import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { QRCode } from 'react-qrcode-logo'
import ReactToPrint from 'react-to-print'

import AlertDialog from '~/client/src/desktop/components/AlertDialog/AlertDialog'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { AvatarSize } from '~/client/src/shared/components/Avatar/Avatar'
import EmailLinkerDialog from '~/client/src/shared/components/EmailLinkerDialog/EmailLinkerDialog'
import * as Icons from '~/client/src/shared/components/Icons'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  SCAN_CODE_SEPARATOR,
  ScanCodeTypes,
} from '~/client/src/shared/models/Scanner'
import User from '~/client/src/shared/models/User'
import { SHOW_FULLSCREEN_PREVIEW } from '~/client/src/shared/stores/EventStore/eventConstants'
import AuthenticationStore from '~/client/src/shared/stores/domain/Authentication.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import SubmitButton from '../SubmitButton'
import ProfileStore, { FieldIds, UserDetailsSections } from './Profile.store'
import ProfileFormFieldWrapper from './components/ProfileFormFieldWrapper'

import Colors from '~/client/src/shared/theme.module.scss'

const SUBMIT_BUTTON_WIDTH = 160
const QR_CODE_TO_PRINT_SIZE = 700
const QR_CODE_LOGO_SRC = '/static/icons/strux-hub-dark-with-background.svg'

interface IProps {
  initProjectId: string

  auth?: AuthenticationStore
  eventsStore?: DesktopEventStore
  companiesStore?: CompaniesStore
  projectMembersStore?: ProjectMembersStore
  userProjectsStore?: UserProjectsStore
}

@inject(
  'auth',
  'eventsStore',
  'companiesStore',
  'projectMembersStore',
  'userProjectsStore',
)
@observer
export default class Profile extends React.Component<IProps> {
  private readonly store: ProfileStore = null
  private fullscreenHolder: HTMLDivElement

  public constructor(props: IProps) {
    super(props)

    this.store = new ProfileStore(
      props.eventsStore,
      props.projectMembersStore,
      props.companiesStore,
    )
  }

  public componentDidMount(): void {
    this.store.assignDefaultValuesToFields()
  }

  public componentDidUpdate(oldProp: IProps): void {
    if (oldProp.initProjectId !== this.props.initProjectId) {
      this.store.assignDefaultValuesToFields()
    }
  }

  public render(): JSX.Element {
    const {
      shouldShowModal,
      closeAlertModal,
      isEmailLinkingDialogOpen,
      hideEmailLinkingDialog,
      onEmailLinkingComplete,
    } = this.store

    return (
      <div className="ml40">
        <AlertDialog
          title={Localization.translator.userSettings}
          isOpen={shouldShowModal}
          onClose={closeAlertModal}
        >
          {this.renderStatusMessage()}
        </AlertDialog>
        <EmailLinkerDialog
          isOpen={isEmailLinkingDialogOpen}
          onClose={hideEmailLinkingDialog}
          onComplete={onEmailLinkingComplete}
        />
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    )
  }

  private renderHeader(): JSX.Element {
    const { currentUser } = this.store
    return (
      <div className="row bb-light-grey mb40">
        <h1 className="row text huge no-grow no-white-space-wrap">
          {User.getFullNameToDisplay(currentUser, this.props.userProjectsStore)}
        </h1>
        <label className="row ml8 text light large">{this.userRoles}</label>
      </div>
    )
  }

  private renderBody(): JSX.Element {
    const {
      fields,
      updateProfile,
      isUpdating,
      isSubmitBtnDisabled,
      currentUser,
      updateAvatarField,
      avatarField,
    } = this.store

    return (
      <div className="profile-fields-holder" style={{ width: '450px' }}>
        <UserProfilePreview
          user={currentUser}
          avatarSize={AvatarSize.Large}
          isAvatarUpdateAllowed={true}
          onAvatarChange={updateAvatarField}
          newAvatarUrl={avatarField.value as string}
          isUserNameClickable={false}
          isCompact={true}
        />
        {fields
          .filter(
            field =>
              field.section === UserDetailsSections.PROFILE &&
              field.id !== FieldIds.AVATAR,
          )
          .map(field => (
            // TODO: Try to re-use <FormFieldWrapper /> as common
            <ProfileFormFieldWrapper key={field.id} {...field} />
          ))}
        {this.renderQRsToPrint()}
        {this.renderQRCode()}
        <SubmitButton
          text={Localization.translator.saveProfile}
          width={SUBMIT_BUTTON_WIDTH}
          loading={isUpdating}
          clickHandler={updateProfile}
          disabled={isSubmitBtnDisabled}
        />

        {this.renderPasswordSection()}
      </div>
    )
  }

  private renderPasswordSection(): JSX.Element {
    const { auth } = this.props

    if (!auth.withPasswordProvider) return null

    const { fields, changePassword, passwordChangeError, isPasswordChanging } =
      this.store
    const shouldShowErrorText = !!passwordChangeError?.trim()

    return (
      <>
        {fields
          .filter(
            field => field.section === UserDetailsSections.PASSWORD_CHANGER,
          )
          .map(field => (
            // TODO: Try to re-use <FormFieldWrapper /> as common
            <ProfileFormFieldWrapper key={field.id} {...field} />
          ))}
        <span
          className={classList({
            text: true,
            red: shouldShowErrorText,
            light: !shouldShowErrorText,
          })}
        >
          {shouldShowErrorText
            ? passwordChangeError
            : Localization.translator.passwordErrors.weakPassword}
        </span>

        <SubmitButton
          text={Localization.translator.changePassword}
          width={SUBMIT_BUTTON_WIDTH}
          loading={isPasswordChanging}
          clickHandler={changePassword}
          disabled={!!passwordChangeError}
        />
      </>
    )
  }

  private renderStatusMessage(): JSX.Element {
    const { warningMessage } = this.store

    return (
      <div
        className={classList({
          'text large pt5': true,
          red: !!warningMessage,
          green: !warningMessage,
        })}
      >
        {warningMessage || Localization.translator.successfullyUpdated}
      </div>
    )
  }

  private get userRoles(): string {
    const { userActiveProjectSettings: userProject } =
      this.props.eventsStore.appState
    const {
      isDocMaster,
      isPresentationUser,
      isAdmin,
      isOwner,
      isInspector,
      isFormsMaster,
      hasActivityViewResponsibility,
      hasActivityUpdateResponsibility,
      hasActivityUploadResponsibility,
      hasActivityNotesResponsibility,
    } = userProject

    const userRoles = []

    const roleNames = Localization.translator.userProfileRoleNames
    if (isOwner) {
      userRoles.push(roleNames.owner)
    } else if (isAdmin) {
      userRoles.push(roleNames.admin)
    }
    if (isDocMaster) {
      userRoles.push(roleNames.docMaster)
    }
    if (isPresentationUser) {
      userRoles.push(roleNames.presentationUser)
    }
    if (isInspector) {
      userRoles.push(roleNames.inspector)
    }
    if (isFormsMaster) {
      userRoles.push(roleNames.formsMaster)
    }
    if (hasActivityViewResponsibility) {
      userRoles.push(roleNames.scheduleViewing)
    }
    if (hasActivityUpdateResponsibility) {
      userRoles.push(roleNames.scheduleUpdating)
    }
    if (hasActivityUploadResponsibility) {
      userRoles.push(roleNames.scheduleUploading)
    }
    if (hasActivityNotesResponsibility) {
      userRoles.push(roleNames.scheduleNotes)
    }

    return userRoles.join(', ')
  }

  private renderQRCode(): JSX.Element {
    return (
      <div className="no-grow col qr-code-holder relative x-center">
        <div className="absolute qr-code-download beautiful-shadow brada4 ba-palette-brand-lighter pa5">
          <ReactToPrint
            trigger={this.renderPrintTrigger}
            content={this.getPrintContentHome}
          />
        </div>
        <div className="col" onClick={this.openQR}>
          <QRCode
            value={this.userQRValue}
            logoImage={QR_CODE_LOGO_SRC}
            size={240}
            fgColor={ThemeMode.getHEXColor(Colors.neutral0)}
          />
        </div>
      </div>
    )
  }

  private get userQRValue(): string {
    return `${ScanCodeTypes.user}${SCAN_CODE_SEPARATOR}${this.store.currentUser.id}`
  }

  private getPrintContentHome = (): HTMLDivElement => {
    return this.fullscreenHolder
  }

  private renderPrintTrigger(): JSX.Element {
    return <Icons.CloudDownload />
  }

  private openQR = (): void => {
    this.props.eventsStore.dispatch(
      SHOW_FULLSCREEN_PREVIEW,
      null,
      null,
      null,
      this.userQRValue,
    )
  }

  private renderQRsToPrint(): JSX.Element {
    const { currentUser } = this.store

    return (
      <div className="hidden">
        <div
          ref={node => (this.fullscreenHolder = node)}
          className="qr-code-to-print-holder"
        >
          <div className="col x-center">
            <UserProfilePreview
              user={currentUser}
              avatarSize={AvatarSize.Large}
            />
            <QRCode
              value={this.userQRValue}
              logoImage={QR_CODE_LOGO_SRC}
              size={QR_CODE_TO_PRINT_SIZE}
              fgColor={ThemeMode.getHEXColor(Colors.neutral0)}
            />
          </div>
        </div>
      </div>
    )
  }
}
