import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { RouteComponentProps } from 'react-router'

import NavBar from '~/client/src/desktop/components/NavBar/NavBar'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import FeatureFlag from '~/client/src/shared/components/FeatureFlag'
import * as Layout from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import FlaggedFeatures from '~/client/src/shared/enums/FlaggedFeatures'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import isTestEnvironment from '~/client/src/shared/utils/environment'

import GeneralMapViewSetUp from '../../components/GeneralMapViewSetUp/GeneralMapViewSetUp'
import DesktopInitialState from '../../stores/DesktopInitialState'
import ProjectSetUpPageStore from './ProjectSetUpPage.store'
import ProjectSetUpPage from './ProjectSetUpPages'
import UnsafeActivityFilters from './components/ActivityFilters/ActivityFilters'
import UnsafeAdminSummary from './components/AdminSummary/AdminSummary'
import UnsafeAnalyticsSetup from './components/AnalyticsSetup/AnalyticsSetup'
import UnsafeIntegrationsSetUp from './components/AppsIntegrations/IntegrationsSetUp'
import UnsafeCompaniesDirectory from './components/CompaniesDirectory/CompaniesDirectory'
import UnsafeDeliveryCardsSetUp from './components/DeliveryCalendarSetUp/DeliveryCardsSetUp'
import UnsafeDeliveryRestrictions from './components/DeliveryRestrictions/DeliveryRestrictions'
import UnsafeMaterialsUpload from './components/MaterialsUpload/MaterialsUpload'
import NotificationsSettings from './components/NotificationsSettings/NotificationsSettings'
import UnsafeNotificationsSetup, {
  NotificationSetupModes,
} from './components/NotificationsSetup/NotificationsSetup'
import UnsafeObjectPropertiesSetup from './components/ObjectPropertiesSetup/ObjectPropertiesSetup'
import UnsafePermitsSetUp from './components/PermitsSetUp/PermitsSetUp'
import UnsafePresentationMode from './components/PresentationMode/PresentationMode'
import UnsafeProfile from './components/Profile/Profile'
import UnsafeProjectCreation from './components/ProjectCreation/ProjectCreation'
import UnsafeProjectMembersUpload from './components/ProjectMembersUpload/ProjectMembersUpload'
import UnsafeProjectScheduleUpload from './components/ProjectScheduleUpload/ProjectScheduleUpload'
import UnsafeProjectWorkflowsSetUp from './components/ProjectWorkflowsSetUp/DeliveryWorkflows'
import UnsafeProjectWorkspaceSetUp from './components/ProjectWorkspaceSetUp/ProjectWorkspaceSetUp'
import UnsafeQRCodes from './components/QRCodes/QRCodes'
import SetUpNavigation from './components/SetUpSteps/SetUpNavigation'
import SitemapsSettingsUnsafe from './components/Sitemaps/SitemapsSettings'
import UnsafeTagsDirectory from './components/TagsDirectory/TagsDirectory'
import UnsafeTrackingHierarchy from './components/TrackingHierarchy/TrackingHierarchy'

import './ProjectSetUp.scss'

const ProjectScheduleUpload = withErrorBoundary(UnsafeProjectScheduleUpload)
const AdminSummary = withErrorBoundary(UnsafeAdminSummary)
const ProjectMembersUpload = withErrorBoundary(UnsafeProjectMembersUpload)
const QRCodes = withErrorBoundary(UnsafeQRCodes)
const ProjectWorkflowsSetUp = withErrorBoundary(UnsafeProjectWorkflowsSetUp)
const ProjectWorkspaceSetUp = withErrorBoundary(UnsafeProjectWorkspaceSetUp)
const PermitsSetUp = withErrorBoundary(UnsafePermitsSetUp)
const ActivityFilters = withErrorBoundary(UnsafeActivityFilters)
const TrackingHierarchy = withErrorBoundary(UnsafeTrackingHierarchy)
const SitemapsSettings = withErrorBoundary(SitemapsSettingsUnsafe)
const PresentationMode = withErrorBoundary(UnsafePresentationMode)
const IntegrationsSetUp = withErrorBoundary(UnsafeIntegrationsSetUp)
const ObjectPropertiesSetup = withErrorBoundary(UnsafeObjectPropertiesSetup)
const NotificationsSetup = withErrorBoundary(UnsafeNotificationsSetup)
const AnalyticsSetup = withErrorBoundary(UnsafeAnalyticsSetup)
const CompaniesDirectory = withErrorBoundary(UnsafeCompaniesDirectory)
const TagsDirectory = withErrorBoundary(UnsafeTagsDirectory)
const DeliveryRestrictions = withErrorBoundary(UnsafeDeliveryRestrictions)
const DeliveryCardsSetUp = withErrorBoundary(UnsafeDeliveryCardsSetUp)
const ProjectCreation = withErrorBoundary(UnsafeProjectCreation)
const MaterialsUpload = withErrorBoundary(UnsafeMaterialsUpload)

interface IProjectSetUpParams {
  projectsStore?: ProjectsStore
  eventsStore?: DesktopEventStore
  common?: DesktopCommonStore
  projectMembersStore?: ProjectMembersStore
  globeViewsStore?: GlobeViewsStore
}

const flaggedFeatures = [
  {
    feature: FlaggedFeatures.PROJECT_CREATION,
    defaultValue: true,
  },
  {
    feature: FlaggedFeatures.TRACKER_PROJECT_TYPE_TOGGLING,
    defaultValue: true,
  },
  {
    feature: FlaggedFeatures.DELIVERIES_PROJECT_TYPE_TOGGLING,
    defaultValue: true,
  },
  {
    feature: FlaggedFeatures.FORMS_PROJECT_TYPE_TOGGLING,
    defaultValue: true,
  },
  {
    feature: FlaggedFeatures.LOGISTICS_PROJECT_TYPE_TOGGLING,
    defaultValue: true,
  },
  {
    feature: FlaggedFeatures.MATERIALS_PROJECT_TYPE_TOGGLING,
    defaultValue: true,
  },
  {
    feature: FlaggedFeatures.ALLOW_PHOTOS,
    defaultValue: true,
  },
  {
    feature: FlaggedFeatures.ALLOW_ANALYTICS,
    defaultValue: true,
  },
]

const profilePageRelatedEvents = [
  e.GET_CURRENT_USER,
  e.GET_AUTH_USER_PROJECT,
  e.LOAD_AND_LISTEN_TO_COMPANIES,
  e.LOAD_AND_LISTEN_TO_PROJECT_ROLES,
]

const integrationsPageRelatedEvents = [
  e.INIT_PROJECT_INTEGRATIONS,
  e.GET_MATURIX_CONFIGURATION,
]

const pagesWithoutPadding = [
  ProjectSetUpPage.PROJECT_CREATION,
  ProjectSetUpPage.MAP_SETUP,
  ProjectSetUpPage.FORM_WORKFLOWS,
  ProjectSetUpPage.DELIVERY_CARDS,
]

@inject(
  'projectsStore',
  'eventsStore',
  'common',
  'projectMembersStore',
  'globeViewsStore',
)
@observer
export default class ProjectSetUp extends React.Component<
  IProjectSetUpParams & RouteComponentProps<any>
> {
  private readonly store: ProjectSetUpPageStore

  public constructor(props: IProjectSetUpParams & RouteComponentProps<any>) {
    super(props)

    this.store = new ProjectSetUpPageStore(
      props.eventsStore,
      props.common,
      props.projectMembersStore,
    )
  }

  public UNSAFE_componentWillMount() {
    const { search } = this.props.location
    this.store.applyPageFromQueryParams(search)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    this.store.applyPageFromQueryParams(nextProps.location.search)
  }

  public componentDidMount() {
    const { projectsStore } = this.props
    projectsStore.loadProjectsList()
  }

  public render() {
    const { isAdmin, isActivityUploadAvailable } = this.store

    const shouldShowAllSettings = isAdmin
    const shouldShowScheduleSettings = isActivityUploadAvailable

    return (
      <Layout.View>
        <Layout.Header className="layout-navigation">
          <NavBar currentSettingsPage={this.page} />
        </Layout.Header>
        <Layout.Content>
          <div className="project-setup-container">
            <SetUpNavigation projectSetUpPageStore={this.store} />
            <div
              className={classList({
                'project-setup-mainframe': true,
                pl20: !pagesWithoutPadding.includes(this.page),
                'overflow-auto':
                  this.page !== ProjectSetUpPage.MAP_SETUP &&
                  this.page !== ProjectSetUpPage.DELIVERY_CARDS,
                'overflow-hidden':
                  this.page === ProjectSetUpPage.MAP_SETUP ||
                  this.page === ProjectSetUpPage.DELIVERY_CARDS,
              })}
            >
              <>
                {this.page === ProjectSetUpPage.DEFAULT && (
                  <AdminSummary projectSetUpPageStore={this.store} />
                )}
                {this.page === ProjectSetUpPage.EDIT_PROFILE &&
                  this.renderProfile()}
                {this.page === ProjectSetUpPage.NOTIFICATION_SETTINGS && (
                  <NotificationsSettings />
                )}
                {this.page === ProjectSetUpPage.QR_SCANNERS_SETUP && (
                  <QRCodes projectSetUpPageStore={this.store} />
                )}
              </>
              {shouldShowScheduleSettings && (
                <>
                  {this.page === ProjectSetUpPage.SCHEDULE_UPLOAD && (
                    <ProjectScheduleUpload projectSetUpPageStore={this.store} />
                  )}
                  {this.page === ProjectSetUpPage.SCHEDULE_COLUMNS && (
                    <ActivityFilters projectSetUpPageStore={this.store} />
                  )}
                  {this.page === ProjectSetUpPage.MOBILE_CONFIGURATION && (
                    <TrackingHierarchy />
                  )}
                  {this.page === ProjectSetUpPage.ON_TIME_THRESHOLDS && (
                    <SitemapsSettings />
                  )}
                </>
              )}
              {shouldShowAllSettings && (
                <>
                  {this.page === ProjectSetUpPage.PROJECT_DETAILS &&
                    this.renderFeatureFlag(this.renderFlaggedContent)}
                  {this.page === ProjectSetUpPage.PROJECT_CREATION &&
                    this.renderFeatureFlag(this.renderProjectCreationContent)}
                  {this.page === ProjectSetUpPage.USER_DIRECTORY && (
                    <ProjectMembersUpload projectSetUpPageStore={this.store} />
                  )}
                  {this.page === ProjectSetUpPage.COMPANY_DIRECTORY && (
                    <CompaniesDirectory projectSetUpPageStore={this.store} />
                  )}
                  {this.page === ProjectSetUpPage.USER_TAGS && (
                    <TagsDirectory projectSetUpPageStore={this.store} />
                  )}
                  {this.page === ProjectSetUpPage.PRESENTATION_MODE && (
                    <PresentationMode />
                  )}
                  {this.page === ProjectSetUpPage.FORM_WORKFLOWS && (
                    <PermitsSetUp />
                  )}
                  {this.page === ProjectSetUpPage.DELIVERY_WORKFLOWS && (
                    <ProjectWorkflowsSetUp projectSetUpPageStore={this.store} />
                  )}
                  {this.page === ProjectSetUpPage.DELIVERY_LOCATIONS && (
                    <DeliveryRestrictions projectSetUpPageStore={this.store} />
                  )}
                  {this.page === ProjectSetUpPage.DELIVERY_CARDS && (
                    <DeliveryCardsSetUp />
                  )}
                  {this.page === ProjectSetUpPage.LOCATION_CONTROLS && (
                    <ObjectPropertiesSetup projectSetUpPageStore={this.store} />
                  )}
                  {this.page === ProjectSetUpPage.DELIVERY_NOTIFICATIONS && (
                    <NotificationsSetup
                      projectSetUpPageStore={this.store}
                      mode={NotificationSetupModes.delivery}
                    />
                  )}
                  {this.page === ProjectSetUpPage.MAP_SETUP &&
                    this.renderMapView()}
                  {this.page === ProjectSetUpPage.INTEGRATIONS &&
                    this.renderIntegrationsSetUp()}
                  {this.page === ProjectSetUpPage.ANALYTICS && (
                    <AnalyticsSetup />
                  )}
                  {this.page === ProjectSetUpPage.MATERIALS_UPLOAD && (
                    <MaterialsUpload projectSetUpPageStore={this.store} />
                  )}
                  {this.page === ProjectSetUpPage.FORM_NOTIFICATIONS && (
                    <NotificationsSetup
                      projectSetUpPageStore={this.store}
                      mode={NotificationSetupModes.form}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Layout.Content>
        <Layout.Footer>
          <div />
        </Layout.Footer>
      </Layout.View>
    )
  }

  private get appState(): DesktopInitialState {
    return this.props.eventsStore.appState
  }

  private get page() {
    return this.store.currentPage
  }

  private get activeProjectId(): string {
    return this.props.eventsStore.appState.activeProject.id
  }

  private renderFeatureFlag(renderContent) {
    return (
      <FeatureFlag
        features={flaggedFeatures.map(f => f.feature)}
        defaultValues={flaggedFeatures.map(
          f => f.defaultValue ?? isTestEnvironment(),
        )}
        render={renderContent}
      />
    )
  }

  private renderFlaggedContent = ([
    isProjectCreationAvailable,
    isTrackerToggleAvailable,
    isDeliveriesToggleAvailable,
    isFormsToggleAvailable,
    isLogisticsToggleAvailable,
    isMaterialsToggleAvailable,
    isPhotosAvailable,
    isAnalyticsAvailable,
  ]: boolean[]) => {
    return this.appState.isLoading ? (
      <Loader />
    ) : (
      <ProjectWorkspaceSetUp
        projectSetUpPageStore={this.store}
        initProjectId={this.activeProjectId}
        isProjectCreationAvailable={isProjectCreationAvailable}
        isTrackerToggleAvailable={isTrackerToggleAvailable}
        isDeliveriesToggleAvailable={isDeliveriesToggleAvailable}
        isLogisticsToggleAvailable={isLogisticsToggleAvailable}
        isMaterialsToggleAvailable={isMaterialsToggleAvailable}
        isFormsToggleAvailable={isFormsToggleAvailable}
        isPhotosAvailable={isPhotosAvailable}
        isAnalyticsAvailable={isAnalyticsAvailable}
      />
    )
  }

  private renderProjectCreationContent = ([
    isProjectCreationAvailable,
    isTrackerToggleAvailable,
    isDeliveriesToggleAvailable,
  ]: boolean[]) => {
    if (isProjectCreationAvailable) {
      return <ProjectCreation projectSetUpPageStore={this.store} />
    }

    return this.renderFlaggedContent([
      isProjectCreationAvailable,
      isTrackerToggleAvailable,
      isDeliveriesToggleAvailable,
    ])
  }

  private renderProfile(): JSX.Element {
    const { loading } = this.appState
    const isLoading = profilePageRelatedEvents.some(event => loading.get(event))

    return isLoading ? (
      <Loader />
    ) : (
      <UnsafeProfile initProjectId={this.activeProjectId} />
    )
  }

  private renderMapView(): JSX.Element {
    const { isDataReceived } = this.props.globeViewsStore
    return isDataReceived ? (
      <GeneralMapViewSetUp projectSetUpPageStore={this.store} />
    ) : (
      <Loader />
    )
  }

  private renderIntegrationsSetUp(): JSX.Element {
    const { loading } = this.appState
    const isLoading =
      integrationsPageRelatedEvents.some(event => loading.get(event)) ||
      this.appState.isLoading

    return isLoading ? (
      <Loader />
    ) : (
      <IntegrationsSetUp
        initProjectId={this.activeProjectId}
        projectSetUpPageStore={this.store}
      />
    )
  }
}
