import * as React from 'react'

import {
  Classes,
  Popover,
  PopoverPosition,
  PopperModifiers,
} from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { PermitFieldType } from '~/client/graph'
import formFieldsCaptionMap from '~/client/src/desktop/constants/formFieldsCaptionMap'
import PermitFieldIcon from '~/client/src/shared/components/PermitFieldIcon/PermitFieldIcon'

interface IProps {
  addNewField(fieldType: PermitFieldType): void

  restrictedFields?: PermitFieldType[]
  disabledFields?: PermitFieldType[]
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: true,
    padding: 10,
    boundariesElement: 'scrollParent',
  },
  hide: { enabled: true },
  computeStyle: { gpuAcceleration: false },
}

enum FieldCategory {
  WorkflowFields = 'Workflow fields',
  BasicFields = 'Basic form fields',
  FormAttachments = 'Form attachments',
  FormSections = 'Form sections',
}

const fieldsByCategoryMap = {
  [FieldCategory.WorkflowFields]: [
    PermitFieldType.Company,
    PermitFieldType.Requester,
    PermitFieldType.Assignee,
    PermitFieldType.Subscriber,
    PermitFieldType.Location,
    PermitFieldType.Equipment,
    PermitFieldType.RequestToCloseLocations,
    PermitFieldType.StartFinishDates,
    PermitFieldType.Material,
  ],
  [FieldCategory.BasicFields]: [
    PermitFieldType.Divider,
    PermitFieldType.Text,
    PermitFieldType.InputText,
    PermitFieldType.InputTextArea,
    PermitFieldType.InputNumber,
    PermitFieldType.Time,
    PermitFieldType.Table,
    PermitFieldType.AdditionalDate,
    PermitFieldType.User,
    PermitFieldType.LocationInformational,
    PermitFieldType.Select,
    PermitFieldType.Measure,
    PermitFieldType.Email,
    PermitFieldType.PhoneNumber,
    PermitFieldType.Instructions,
    PermitFieldType.Checklist,
    PermitFieldType.Question,
    PermitFieldType.RichText,
  ],
  [FieldCategory.FormAttachments]: [PermitFieldType.File],
  [FieldCategory.FormSections]: [
    PermitFieldType.Section,
    PermitFieldType.Subsection,
  ],
}

@observer
export default class FormFieldsModal extends React.PureComponent<IProps> {
  public render() {
    return (
      <Popover
        popoverClassName="form-fields-modal"
        position={PopoverPosition.RIGHT_TOP}
        modifiers={popoverPopperModifiers}
        content={this.modalFields}
      >
        {this.props.children}
      </Popover>
    )
  }

  private get modalFields(): JSX.Element {
    const { restrictedFields, disabledFields } = this.props

    return (
      <div className="col mw270 px15 pointer overflow-auto form-fields-modal-content">
        {Object.keys(fieldsByCategoryMap).map(category => {
          const fieldsList: PermitFieldType[] = fieldsByCategoryMap[
            category
          ].filter(f => !restrictedFields?.includes(f))

          if (!fieldsList?.length) {
            return null
          }

          return (
            <React.Fragment key={category}>
              <span className="text extra-large py10 bb-palette-brand-lighter">
                {category}
              </span>
              {fieldsList.map(field => (
                <div
                  key={field}
                  className={classList({
                    'row text large py10': true,
                    'unclickable-element opacity3':
                      disabledFields?.includes(field),
                    [Classes.POPOVER_DISMISS]: !disabledFields?.includes(field),
                  })}
                  onClick={this.addNewField.bind(null, field)}
                >
                  <PermitFieldIcon fieldName={field} />
                  {formFieldsCaptionMap[field]}
                </div>
              ))}
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  private addNewField = (field: PermitFieldType) => {
    const { disabledFields, restrictedFields, addNewField } = this.props

    if (disabledFields?.includes(field) || restrictedFields?.includes(field)) {
      return
    }

    addNewField(field)
  }
}
